.burger__container {
    display: none;
}

.burger__container_open {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    background-color: #5C3EDE;
    padding-top: calc(43.906px + 22px + 22px);
    padding-left: 15px;
    padding-right: 15px;
    z-index: 55;
}

.icon {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 30px;
    height: 30px;
}

.text {
    padding: 0;
    margin: 0;
    text-align: center;
    color: var(--color-white);
    font-family: var(--ff-sans);
    line-height: normal;
    font-weight: var(--fw-normal);
}

.overlay {
    display: none;
}

.button__text {
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 12px;
    color: #000;
}

.overlay__open {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    visibility: visible;
    background-color: rgba(0, 0, 0, .5);
    opacity: 1;
    transition: visibility 1s, opacity 0.5s ease-in-out;
    align-items: center;
    justify-content: center;
    z-index: 12;
}

.burger__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.button__container {
    width: 180px;
    height: 31px;
    margin-top: 28px;
    margin-bottom: 59px;
    z-index: 100;
}

@media screen and (max-width: 800px) {
    .menu {
        display: none;
    }

    .overlay {
        display: inline;
    }

    .menu__icon {
        display: inline;
    }

    .container {
        justify-content: space-between;
    }
}

@media screen and (max-width: 350px) {
    .icon {
        width: 12px;
        height: 12px;
    }

    .text {
        color: #FFF;
        font-family: var(--ff-sans);
        font-size: 3.6vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .burger__menu {
        gap: 10px;
    }

    .button__container {
        width: 180px;
        height: 31px;
        margin-top: 28px;
        margin-bottom: 59px;
    }
}