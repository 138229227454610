.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.main__container {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 120px;
    flex: 1;
}

.button__text {
    padding: 0;
    margin: 0;
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 24px;
    color: #fff;
}

.img__container {
    max-width: 698px;
    height: auto;
    order: 2;
    margin-left: 13.7%;
}

.image {
    max-width: 100%;
    height: auto;
    margin-top: 18px;
}

.text__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    order: 1;
}

.title {
    max-width: 660px;
    margin: 0;
    padding: 0;
    margin-bottom: 31px;
    font-size: var(--fz-title);
    font-family: var(--ff-monts);
    line-height: 52px;
    font-weight: var(--fw-medium);
}

.text {
    max-width: 660px;
    margin: 0;
    padding: 0;
    margin-bottom: 39px;
    font-size: var(--fz-subtitle);
    font-family: var(--ff-sans);
    line-height: normal;
    font-weight: var(--fw-normal);
}

.button__container {
    width: 382px;
    height: 87px;
}

.button {
    margin: 0;
    padding: 0;
}

.second_container {
    margin: auto;
    padding: 0;
}

.table {
    display: flex;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 135px;
    padding: 0;
    margin: 0;
    padding-right: 130px;
    padding-left: 5px;
}

.table__item {
    display: flex;
    gap: 57px;
    width: 368px;
    align-items: center;
}

.table__count {
    margin: 0;
    padding: 0;
    color: #5C3EDE;
    font-family: var(--ff-monts);
    font-size: 70px;
    font-weight: var(--fw-medium);
    line-height: normal;
}

.table__title {
    margin: 0;
    padding: 0;
    color: #5C3EDE;
    font-family: var(--ff-monts);
    font-size: 70px;
    font-weight: var(--fw-medium);
    line-height: normal;
}

.table__cell {
    max-width: 273px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    text-align: left;
}

.table__text {
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-size: var(--fz-desc);
    font-weight: var(--fw-normal);
    line-height: normal;
}

@media screen and (max-width: 620px) {
    .main__container {
        flex-direction: column;
        gap: 25px;
    }

    .img__container {
        margin: 0;
        order: 1;
    }


    .title {
        max-width: 12%;
        font-size: 35px;
    }

    .table {
        padding: 0;
    }
}

@media screen and (max-width: 480px) {
    .main__container {
        flex-direction: column;
        gap: 25px;
    }

    .img__container {
        margin: 0;
        order: 1;
    }

    .button__container {
        height: 49px;
        width: 100%;
    }

    .text__container {
        gap: 30px;
    }

    .title {
        font-size: 25px;
        line-height: 100%;
        max-width: 100%;
        margin: 0;
    }

    .title:last-of-type {
        margin-bottom: 30px;
    }

    .table {
        gap: 30px;
    }

    .table__item {
        gap: 30px;
        width: 100%;
    }

    .table__text {
        font-size: 16px;
    }

    .table__title {
        font-size: 18px;
    }

    .table__count {
        font-size: 40px;
    }

    .main__container {
        margin-bottom: 30px;
    }

    .text {
        font-size: 16px;
        margin: 0;
    }

    .table {
        padding: 0;
    }

    .button__text {
        font-size: 16px;
    }
}