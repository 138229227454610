.container {
    margin: 0;
    padding: 47px 12.1% 71px 12.1%;
}

@media screen and (max-width: 480px) {
    .container {
        padding: 30px 10px 30px 10px;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
    }
}