.container {
    display: flex;
    gap: 135px;
}


.title {
    margin: 0;
    padding: 0;
    margin-bottom: 70px;
    margin-top: 30px;
    color: #000;
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-medium);
    line-height: 52px;
}

.list__item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    color: #000;
    font-family: var(--ff-sans);
    font-size: var(--fz-text);
    font-weight: var(--fw-normal);
    line-height: 122.222%;
}

.list__item::before {
    content: '\2022';
    color: #000;
    font-size: 20px;
    position: absolute;
    left: -22px;
}

.img__container {
    max-width: 572px;
    max-height: 572px;
}

.img {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 700px) {
    .container {
        flex-direction: column;

    }
}

@media screen and (max-width: 480px) {
    .title {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 25px;
    }

    .container {
        gap: 0;
    }

    .list__item {
        font-size: 16px;
    }
}