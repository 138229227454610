.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    margin: 0;
    padding: 0;
    margin-top: 100px;
    margin-bottom: 100px;
    width: 100%;
    max-width: 700px;
    font-family: var(--ff-monts);
    font-size: 50px;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
}

.subtitle__container {
    background-color: #5C3EDE;
    margin: 0;
    margin-top: 104px;
    padding-top: 74px;
    padding-bottom: 74px;
    display: flex;
    justify-content: center;
    flex: 1;
}

.subtitle {
    color: #fff;
    margin: 0;
    padding: 0;
    max-width: 1311px;
    width: 100%;
    font-family: var(--ff-monts);
    font-size: 50px;
    font-weight: 400;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
}

.logo__container {
    background-color: #5C3EDE;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    padding-right: 264px;
    padding-left: 264px;
    padding-bottom: 81px;
    align-items: center;
}

.logo {
    max-width: 100%;
}

.logo__img {
    width: 100%;
}

@media screen and (max-width:1200px) {
    .logo__container {
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (max-width:825px) {
    .title {
        font-size: 25px;
        line-height: 25px;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .subtitle__container {
        margin-top: 38px;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 28px;
        padding-right: 28px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 25px;
    }

    .logo__container {
        gap: 14px;
        padding-left: 14px;
        padding-right: 14px;
        align-items: center;
        padding-bottom: 31px;
    }
}