.slider {
    max-width: 100vw;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 750px;
}

.img__container {
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 450px) {
    .slide {
        width: 187px;
        height: 248px;
    }

    .slider {
        height: 100%;
    }
}