.container {
    display: flex;
    background-color: var(--color-blue);
    padding: 22px 12.1% 22px 12.1%;
    align-items: center;
    z-index: 5;
}

.menu {
    display: flex;
    align-items: center;
    margin-left: 83px;
    justify-content: space-between;
    flex-grow: 1;
}

.button__container {
    max-width: 258px;
    height: 60px;
    flex-grow: 2;
}

.button__text {
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.title {
    padding: 0;
    margin: 0;
    width: 210px;
    text-align: center;
    color: var(--color-white);
    font-size: var(--fz-desc);
    font-family: var(--ff-monts);
    line-height: 122.222%;
    font-weight: var(--fw-medium);
}

.text {
    padding: 0;
    margin: 0;
    text-align: center;
    color: var(--color-white);
    font-size: 16px;
    font-family: var(--ff-sans);
    line-height: normal;
    font-weight: var(--fw-normal);
}

.text:hover {
    cursor: pointer;
}

.nav__container {
    display: flex;
    gap: 51px;
}

.phone_container {
    max-width: calc(368px - 82px);
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    margin-left: 7.2%;
}

.icon {
    display: inline;
    width: 36px;
    height: 36px;
}

.icon:hover {
    -webkit-animation: 0.1s tremor ease-out infinite;
    animation: 0.1s tremor ease-out infinite;
}

.link {
    padding: 0;
    margin: 0;
    text-align: center;
    color: var(--color-white);
    font-size: 16px;
    font-family: var(--ff-sans);
    line-height: normal;
    font-weight: var(--fw-normal);
    text-decoration: none;
    display: flex;
    align-items: center;
}

.link:hover {
    cursor: pointer;
}

.icon__container {
    width: 40px;
    height: 40px;
    display: none;
}

.menu__icon {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1401px) {
    .icon {
        display: none;
    }

    .nav__container {
        gap: 25px;
    }

    .container {
        padding: 22px 3% 22px 3%;
    }
}

@media screen and (max-width: 800px) {
    .menu {
        display: none;
    }

    .icon__container {
        display: inline;
    }

    .container {
        justify-content: space-between;
    }
}

@-webkit-keyframes tremor {

    0%,
    25% {
        left: -1px;
        top: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }

    50%,
    100% {
        left: 1px;
        top: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}

@-moz-keyframes tremor {

    0%,
    25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }

    50%,
    100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}

@-ms-keyframes tremor {

    0%,
    25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }

    50%,
    100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}

@-o-keyframes tremor {

    0%,
    25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }

    50%,
    100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}

@keyframes tremor {

    0%,
    25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }

    50%,
    100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}