@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: 
    url('./fonts/Montserrat-Medium.woff2') format('woff2')
    url('./fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: 
    url('./fonts/OpenSans-Regular.woff2') format('woff2')
    url('./fonts/OpenSans-Regular.woff') format('woff');
}