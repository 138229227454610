@import url('./vendor/fonts.css');

#root {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
}

:root{
    --color-white: #fff;
    --color-blue: #5C3EDE;
    --ff-sans: "Open Sans", Helvetica, Arial, sans-serif;
    --ff-monts: "Montserrat", Helvetica, Arial, sans-serif;
    --fz-title:  50px;
    --fz-text: 24px;
    --fz-subtitle: 28px;
    --fz-desc: 18px;
    --fw-normal: 400;
    --fw-medium: 500;
}