.footer {
    background-color: #5C3EDE;
    padding: 53px 12.1% 46px 12.1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
}

.title {
    margin: 0;
    padding: 0;
    margin-bottom: 21px;
    color: var(--color-white);
    font-family: var(--ff-monts);
    font-size: 35px;
    font-weight: var(--fw-normal);
    line-height: 122.222%;
}

.button__container {
    width: 100%;
    max-width: 251px;
    height: 60px;
}

.button__text {
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

.text {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: var(--ff-sans);
    font-size: 20px;
    font-weight: var(--fw-normal);
    line-height: 122.222%;
    max-width: 382px;
}

.nav__container {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.link {
    color: #FFF;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
    cursor: pointer;
}

.link__container {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
}

.sublink {
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: var(--ff-sans);
    font-size: 20px;
    font-weight: var(--fw-normal);
    line-height: 122.222%;
    max-width: 382px;
}

@media screen and (max-width: 800px) {
    .link__container {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
}

@media screen and (max-width: 700px) {
    .footer {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav__container {
        display: none;
    }

    .button__container {
        width: 100%;
    }

    .link__container {
        gap: 12px;
    }
}

@media screen and (max-width: 480px) {

    .footer {
        padding: 30px 0 30px 10px;
        flex-direction: column;
    }

    .container {
        width: 100%;
    }

    .button__container {
        width: 100%;
        max-width: 95vw;
    }

    .button__text {
        font-size: 16px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .text {
        font-size: 14px;
    }

    .link {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .link__container {
        padding-top: 10px;
        padding-bottom: 56px;
        gap: 8px;
    }

    .sublink {
        font-size: 14px;
        line-height: 16px;
    }
}