.slider {
    display: flex;
    width: 100%;
    max-width: 100%;
    -webkit-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}

.img_container {
    display: flex;
    max-width: 50vw;
    width: 100%;
}


.img {
    width: 100%;
    max-width: 100%;
}