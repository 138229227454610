.button {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: var(--color-white);
    border: none;
    transition: all 0.3s ease;
}

.button:hover {
    opacity: 0.8;
}

.text {
    z-index: 3;
    padding: 0;
    margin: 0;
    text-align: center;
    color: var(--color-white);
    font-family: var(--ff-sans);
    line-height: normal;
    font-weight: var(--fw-normal);
}

@media screen and (max-width: 380px) {
    .text {
        font-size: 14px;
    }
}