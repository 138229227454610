.container {
    display: flex;
    gap: 135px;
    flex: 1;
}

.list__container {
    box-sizing: border-box;
}

.title {
    margin: 0;
    padding: 0;
    margin-bottom: 70px;
    margin-top: 30px;
    color: #fff;
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-medium);
    line-height: 52px;
}

.list__item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    color: #fff;
    font-family: var(--ff-sans);
    font-size: var(--fz-text);
    font-weight: var(--fw-normal);
    line-height: 122.222%;
}

.list__item::before {
    content: '\2022';
    color: #fff;
    font-size: 20px;
    position: absolute;
    left: -22px;
}

.img__container {
    max-width: 572px;
    max-height: 572px;
}

.img {
    max-width: 100%;
    height: 70%;
}

.button__container {
    width: 397px;
    height: 95px;
    margin-top: 47px;
}

.button__text {
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 22px;
    color: #000;
}

@media screen and (max-width: 700px) {
    .container {
        flex-direction: column;

    }
}

@media screen and (max-width: 480px) {
    .title {
        margin-bottom: 30px;
        margin-top: 0;
        font-size: 25px;
    }

    .img__container {
        order: 2;
    }

    .list__item {
        font-size: 16px;
    }

    .button__container {
        width: 100%;
        margin-top: 10px;
        height: 49px;
    }

    .container {
        gap: 30px;
    }

    .button__text {
        font-size: 16px;
    }
}