.title {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-medium);
    line-height: 52px;
    margin-bottom: 86px;
    margin-top: 53px;
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 25px;
        margin-bottom: 30px;
        margin-top: 0;
    }
}