.list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 20px;
}

@media screen and (max-width: 480px) {
    .list {
        padding-left: 20px;
        padding-bottom: 23px;
    }
}