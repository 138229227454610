.overlay {
    display: none;
}

.overlay__open {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    visibility: visible;
    background-color: rgba(0, 0, 0, .5);
    opacity: 1;
    transition: visibility 1s, opacity 0.5s ease-in-out;
    align-items: center;
    justify-content: center;
    z-index: 12;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.main_container {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding-left: 76px;
}

.button__container {
    width: 100%;
    max-width: 350px;
    height: 70px;
}

.image__container {
    width: 100%;
    max-width: 374px;
    height: 374px;
    margin: 0;
    padding: 0;
    padding-top: 74px;
    padding-bottom: 74px;
}

.button__text {
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.close {
    position: absolute;
    top: 37px;
    right: 61px;
    width: 25px;
    height: 25px;
    color: #000;
    cursor: pointer;
}

.popup__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 70px;
}

.title {
    margin: 0;
    padding: 0;
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-normal);
    line-height: 52px;
    margin-bottom: 40px;
}

.text {
    margin: 0;
    padding: 0;
    max-width: 800px;
    font-family: var(--ff-sans);
    font-size: var(--fz-subtitle);
    font-weight: var(--fw-normal);
    line-height: normal;
    margin-bottom: 48px;
}

.form {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    margin-bottom: 25px;
    gap: 30px;
}

.error__span {
    z-index: 23;
    position: absolute;
    bottom: -21px;
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
    color: red;
}

.input__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
}

.input {
    border: 1px solid #D3D3D3;
    padding: 25px 0 25px 28px;
    margin: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
}

.input::placeholder {
    color: #8C8C8C;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
}

.input:focus {
    outline: none;
}

.checkbox__container {
    display: flex;
    position: relative;
}

.checkbox {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.label {
    display: flex;
    cursor: pointer;
}

.checkbox:checked+label::before {
    background-image: url('../../images/Checked.svg');
}

.label::before {
    content: '';
    border: 1px solid #D3D3D3;
    width: 33px;
    height: 31px;
}

.text__secondary {
    width: 100%;
    max-width: 910px;
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
    margin-left: 3%;
}

.image {
    width: 100%;
    height: 100%;
}

.error__span_last{
    z-index: 23;
    position: absolute;
    bottom: -21px;
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
    color: red;
}

@media screen and (max-width: 800px) {
    .main_container {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        justify-content: center;
    }

    .popup__container {
        order: 2;
        margin-right: 0;
    }

    .image__container {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
        width: 267px;
        height: 267px;
    }

    .text__secondary {
        font-size: 16px;
        margin-left: 12px;
        margin-bottom: 65px;
    }

    .title {
        font-size: 25px;
        margin-bottom: 20px;
        line-height: 100%;
    }

    .text {
        font-size: 16px;
    }

    .form {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 17px;
        flex: 0;
    }

    .button__container {
        align-self: center;
        width: 100%;
        height: 48px;
        max-width: 100%;
    }

    .input__container {
        width: 100%;
        max-width: 100%;
    }

    .input {
        padding: 12px 0 12px 28px;
    }

}

@media screen and (max-width: 450px) {
    .text__secondary {
        font-size: 10px;
        margin-bottom: 86px;
        width: 245px;
    }

    .checkbox__container {
        align-items: center;
    }

    .close {
        top: 10px;
        right: 10px;
    }

    .error__span_last {
        bottom: 55px;
    }
}