.main__container {
    display: flex;
    flex-direction: column;
}

.secondary__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1442px;
}

.title {
    margin: 0;
    padding: 0;
    padding-top: 23px;
    margin-bottom: 86px;
    max-width: 608px;
    color: var(--color-white);
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-medium);
    line-height: 52px;
}

.secondary__title {
    margin: 0;
    padding: 0;
    color: var(--color-white);
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-medium);
    line-height: 52px;
    text-align: center;
    margin-top: 97px;
    margin-bottom: 83px;
}

.table {
    display: flex;
    gap: 149px;
    list-style: none;
    max-width: 1442px;
    margin: 0;
    padding: 0;
    margin-bottom: 100px;
}

.table__cell {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 408px;
}

.img {
    width: 78px;
    height: 78px;
}

.table__title {
    margin: 0;
    padding: 0;
    color: var(--color-white);
    font-family: var(--ff-sans);
    font-size: var(--fz-text);
    font-weight: var(--fw-normal);
    line-height: normal;
}

.table__text {
    margin: 0;
    padding: 0;
    color: var(--color-white);
    font-family: var(--ff-sans);
    font-size: var(--fz-desc);
    font-weight: var(--fw-normal);
    line-height: normal;
}

.list__container {
    display: flex;
    gap: 66px;
    max-width: 1442px;
    align-items: center;
}

.list__item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    position: relative;
    color: var(--color-white);
    font-family: var(--ff-sans);
    font-size: var(--fz-text);
    font-weight: var(--fw-normal);
    line-height: 122.222%;
}

.list__item::before {
    content: '\2022';
    color: white;
    font-size: 20px;
    position: absolute;
    left: -22px;
}

@media screen and (max-width: 900px) {
    .table {
        gap: 50px;
    }
}

@media screen and (max-width: 630px) {
    .table {
        flex-direction: column;
    }

    .list__container {
        flex-direction: column;
    }

    .title {
        font-size: 35px;
    }
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 25px;
        margin-bottom: 50px;
    }

    .table__cell {
        align-items: center;
        max-width: 100%;
    }

    .table__text {
        text-align: center;
    }

    .table {
        align-items: center;
        gap: 30px;
        margin-bottom: 39px;
    }

    .list__container {
        gap: 10px;
    }

    .list__item {
        font-size: 16px;
    }

    .secondary__title {
        font-size: 25px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

}