.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.main_container {
    max-width: 1442px;
    display: flex;
    gap: 27px;
    justify-content: center;
    background-color: white;
}

.popup__container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin: 5.2% 7.3% 108px 75px;
}

.title {
    margin: 0;
    padding: 0;
    font-family: var(--ff-monts);
    font-size: var(--fz-title);
    font-weight: var(--fw-normal);
    line-height: 52px;
    margin-bottom: 40px;
}

.text {
    margin: 0;
    padding: 0;
    max-width: 800px;
    font-family: var(--ff-sans);
    font-size: var(--fz-subtitle);
    font-weight: var(--fw-normal);
    line-height: normal;
    margin-bottom: 48px;
}

.form {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 51px;
    margin-bottom: 25px;
    justify-content: space-between;
}

.label_container {
    width: 31.4%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.input {
    height: 100%;
    width: 100%;
    border: 1px solid #D3D3D3;
    padding: 25px 0 25px 28px;
    margin: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
}

.error__span {
    position: absolute;
    bottom: -21px;
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
    color: red;
}

.button__container {
    width: 308px;
    height: 72px;
}

.button__text {
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-weight: 400;
    font-size: 20px;
    color: #fff;
}

.input::placeholder {
    color: #8C8C8C;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
}

.input:focus {
    outline: none;
}

.checkbox__container {
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.checkbox {
    opacity: 0;
    position: absolute;
}

.label {
    display: flex;
    gap: 38px;
    max-width: 800px;
}

.checkbox:checked+label::before {
    background-image: url('../../images/Checked.svg');
}

.label::before {
    content: '';
    border: 1px solid #D3D3D3;
    width: 31px;
    height: 31px;
}

.text__secondary {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: var(--ff-sans);
    font-size: 16px;
    font-weight: var(--fw-normal);
    line-height: normal;
}

.image {
    width: 374px;
    height: 374px;
    margin: 0;
    padding: 0;
    padding-top: 74px;
    padding-bottom: 74px;
}

@media screen and (max-width: 900px) {
    .form {
        flex-direction: column;
        align-items: flex-start;
        gap: 21px;
    }

    .popup__container {
        margin: 5.2% 4% 30px 10px;
    }

    .text {
        margin-bottom: 30px;
    }

    .label_container {
        width: 95%;
    }

    .title {
        margin-bottom: 20px;
        font-size: 35px;
    }

    .text__secondary {
        margin-left: 15px;
    }
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 25px;
    }

    .text {
        font-size: 16px;
    }

    .input {
        padding: 12px 0 12px 13px;
    }

    .button__container {
        width: 100%;
        height: 49px;
    }

    .text__secondary {
        font-size: 10px;
        margin-left: 0;
    }

    .label {
        gap: 17px;
    }

    .button__text {
        font-size: 16px;
    }
}